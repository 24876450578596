import React from "react";
import cl from "./Contact.module.css";
import { MapPinSVG, TelSVG} from "../../../../img/components/SVGImages";
import SocialMedia from "./SocialMedia";

const blueMain = '#0893f0'

function Contact(props) {
  const mapLink =
    "https://www.google.com/maps/@50.6181266,26.2724651,20.3z?authuser=0&entry=ttu";
  const address = "Рівне, вул. Грушевського 44";
  const telNumber = "+38(098)-084-97-83";

  return (
    <div className={cl.contactBlock}>
      <div className={cl.addressBlock}>
        <MapPinSVG />
        <a className={cl[props.textColor]} href={mapLink} target="_blank" rel="noreferrer">
          {address}
        </a>
      </div>
      <div className={cl.telBlock}>
        <TelSVG />
        <a className={cl[props.textColor]} href="tel:+380980849783">{telNumber}</a>
      </div>
      {props.showSocial ? <SocialMedia color={blueMain}/> : null}
    </div>
  );
}

export default Contact;
