import { forwardRef, useContext } from "react";
import SignOnCourseButton from "../../utils/button/SignOnCourseButton";
import cl from "./PriceCard.module.css";
import PriceCardTitle from "./PriceCardTitle";
import PriceCardBody from "./PriceCardBody";
import {Context} from '../../../../context.js'

const PriceCard = forwardRef((props, ref) => {

  const {showModal} = useContext(Context)
  const clickHandler = () => {
    showModal()
  }
  return (
    <div ref={ref}  className={`${cl.card} ${cl[props.card.color]}`}>
      <PriceCardTitle card={props.card}/>
      <PriceCardBody  card={props.card}/>
      <SignOnCourseButton onClick={clickHandler}>
        ЗАПИСАТИСЯ
      </SignOnCourseButton>
    </div>
  );
});

export default PriceCard;
