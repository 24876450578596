import cl from "./VideoBlock.module.css";

const FirstContentSectionList = () => {
  return (
    <div className={cl.branches}>
      <ul className={cl.contentDescriptionListItems}>
        <li className={cl.contentDescriptionListItem}>
          <span>Рівне</span>
        </li>
        <li className={cl.contentDescriptionListItem}>
          <span>Костопіль</span>
        </li>
        <li className={cl.contentDescriptionListItem}>
          <span>Запроіжжя</span>
        </li>
        <li className={cl.contentDescriptionListItem}>
          <span>Хмельницький</span>
        </li>
        <li className={cl.contentDescriptionListItem}>
          <span>Дубровиця</span>
        </li>
      </ul>
      <ul className={cl.contentDescriptionListItems}>
        <li className={cl.contentDescriptionListItem}>
          <span>Сарни</span>
        </li>
        <li className={cl.contentDescriptionListItem}>
          <span>Луцьк</span>
        </li>
        <li className={cl.contentDescriptionListItem}>
          <span>Вараш</span>
        </li>
        <li className={cl.contentDescriptionListItem}>
          <span>Хуст</span>
        </li>
      </ul>
    </div>
  );
};

export default FirstContentSectionList;
