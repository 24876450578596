import TopBar from "./components/UI/blocks/TopBar.jsx";
import "./styles/App.css";
import MainBlock from "./components/UI/blocks/MainBlock.jsx";
import Toast from "./components/UI/utils/Toast/Toast.jsx";
import { React, useEffect, useRef, useState } from "react";
import AboutBlock from "./components/UI/blocks/AboutBlock.jsx";
import ForWhomBlock from "./components/UI/blocks/ForWhomBlock.jsx";
import ProgramContentBlock from "./components/UI/blocks/ProgramContent/ProgramContentBlock.jsx";
import PriceBlock from "./components/UI/blocks/PriceBlock.jsx";
import VideoBlock from "./components/UI/blocks/VideoBlock/VideoBlock.jsx";
import FeedbackBlock from "./components/UI/blocks/FeedbackBlock/FeedbackBlock.jsx";
import CertificatesBlock from "./components/UI/blocks/CertificatesBlock/CertificatesBlock.jsx";
import SignUpToCourseBlock from "./components/UI/blocks/SignUpToCourse/SignUpToCourseBlock.jsx";
import Footer from "./components/UI/blocks/FooterBlock/Footer.jsx";
import { Context } from "./context.js";
import MyModal from "./components/UI/utils/modal/MyModal.jsx";
import SendForm from "./components/UI/blocks/SendForm/SendForm.jsx";
import useScrollBlock from "./components/hooks/useScrollBlock.js";
import NoInternet from "./components/UI/utils/NoInternet/NoInternet.jsx";
import Loader from "./components/UI/utils/Loader/Loader.jsx";

function App() {
  const [blockScroll, allowScroll] = useScrollBlock();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => hideLoader());

  const ref = useRef();

  function showToast(type, title, description) {
    ref.current.showToast(type, title, description);
  }

  const hideLoader = () => { 
    setLoader(false);
  };

  const hideModal = () => {
    allowScroll();
    setModal(false);
  };

  const showModal = () => {
    blockScroll();
    setModal(true);
  };

  return (
    <Context.Provider
      value={{
        hideModal,
        showModal,
        showToast,
      }}
    >
      <div className="App">
        <NoInternet />
        <TopBar />
        <MainBlock />
        <AboutBlock />
        <ForWhomBlock />
        <ProgramContentBlock />
        <PriceBlock />
        <VideoBlock />
        <FeedbackBlock />
        {/* <CertificatesBlock /> */}
        <SignUpToCourseBlock />
        <Footer />

        {loader ? <Loader width='100vw' heights='100vh'/> : null}

        {modal ? (
          <MyModal visible={modal} setVisible={setModal}>
            <SendForm />
          </MyModal>
        ) : null}
        <Toast ref={ref} position="bottom-right" />
      </div>
    </Context.Provider>
  );
}

export default App;
