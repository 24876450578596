import MyInput from "../../utils/input/MyInput.jsx";
import SignOnCourseButton from "../../utils/button/SignOnCourseButton.jsx";
import { SmallCompanyLogoSVG } from "../../../../img/components/SVGImages.jsx";
import cl from "./SendForm.module.css";
import Toast from "../../utils/Toast/Toast.jsx";
import useForm from "../../../hooks/useForm.js";
import { Context } from "../../../../context.js";
import { useContext, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Loader from "../../utils/Loader/Loader.jsx";

const SendForm = () => {
  const { hideModal, showToast } = useContext(Context);
  const { handleChange, values, errors } = useForm();
  const toastRef = useRef();
  const [loader, setLoader] = useState(false);

  function resolveResponse(status, title, message) {
    setLoader(false);
    hideModal();
    showToast(status, title, message);
  }

  const sendUserData = (e) => {
    e.preventDefault();
    if (Object.keys(values).length === 0) {
      toastRef.current.showToast(
        "danger",
        "Увага",
        "Поля не можуть бути порожніми"
      );
    } else if (Object.keys(errors).length === 0) {
      setLoader(true);
      // emailjs
      //   .sendForm(
      //     "service_gxie3q7",
      //     "template_yfzkhvm",
      //     e.target,
      //     "6AqGQj0iewBMwmvsj"
      //   )
      //   .then(
      //     (response) => {
      //       resolveResponse(
      //         "success",
      //         "Дякуємо за звернення",
      //         "Ми зв'яжемося з Вами найближчим часом"
      //       );
      //     },
      //     (err) => {
      //       resolveResponse(
      //         "danger",
      //         "Помилка",
      //         `Сталася помилка. Спробуйте будь-ласка пізніше або зателефонуйте нам!`
      //       );
      //     }
      //   );
    }
  };

  return (
    <form className={cl.sendingForm} onSubmit={sendUserData}>
      {loader ? <Loader width="100vw" height="100vh"  colorBG='rgba(255, 255, 255, 0)'/> : null}
      <div>
        <SmallCompanyLogoSVG className={cl.smallLogo} />
        <h2>ВІДПРАВИТИ ЗАЯВКУ</h2>
      </div>
      <div className={cl.email}>
        <MyInput
          name="email"
          onChange={handleChange}
          type="email"
          placeholder="Поштова скриня"
        />
        {errors.email && <h4>{errors.email}</h4>}
      </div>
      <div className={cl.inputBlock}>
        <div>
          <MyInput
            name="name"
            onChange={handleChange}
            type="text"
            placeholder="Ваше ім'я*"
            minLength="3"
            required
          />
          {errors.name && <h4>{errors.name}</h4>}
        </div>
        <div>
          <MyInput
            name="phone"
            onChange={handleChange}
            type="tel"
            placeholder="Номер телефону*"
            minLength="10"
            required
          />
          {errors.phone && <h4>{errors.phone}</h4>}
        </div>
      </div>
      <div>
        <SignOnCourseButton type="submit">ЗАПИСАТИСЯ</SignOnCourseButton>
        <Toast ref={toastRef} position="bottom-right" />
      </div>
    </form>
  );
};

export default SendForm;
